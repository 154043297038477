<template>
  <RevCarousel
    :alternative-button-label="i18n(translations.alternativeButtonLabel)"
    :alternative-next="i18n(commonTranslations.alternativeNext)"
    :alternative-previous="i18n(commonTranslations.alternativePrevious)"
    :autoplay="true"
    class="relative m-auto block w-full lg:max-w-[168rem]"
    current-index-id="cms-carousel"
    :tracking="trackingData"
    @on-slide-impression="carouselTracking"
  >
    <component
      :is="slide.tag"
      v-for="slide in carouselSlides"
      :key="slide.key"
      class="w-full"
      :rel="slide.link?.rel"
      :target="slide.link?.target"
      :to="slide.link?.href"
      @click="slide.handleSlideClick"
    >
      <ImageRenderer
        :alt="slide.image?.alt"
        :height="slide.image?.height"
        :height-mobile="slide.image?.heightMobile"
        :loading="slide.isLoading"
        :media-max-width="MediaMaxWidth.SM"
        :src="slide.image?.src"
        :src-mobile="slide.image?.srcMobile"
        :width="slide.image?.width"
        :width-mobile="slide.image?.widthMobile"
      />
    </component>
  </RevCarousel>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import type { CarouselContent } from '@backmarket/http-api/src/api-specs-content'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCarousel } from '@ds/components/Carousel'

import commonTranslations from '../../common/translations'
import {
  Loading,
  MediaMaxWidth,
} from '../../constants/image-renderer.constants'
import ImageRenderer from '../../shared-components/ImageRenderer/ImageRenderer.vue'

import translations from './Carousel.translations'

const i18n = useI18n()
const route = useRoute()
const { trackCarouselClick } = useTracking()

export type CarouselProps = ContentBlockProps & CarouselContent

const { slides, tracking } = defineProps<CarouselProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

// The slide impressions are tracked via the DS pubsub
// So we need to provide the tracking content for each slide
const carouselTracking = computed(() => ({
  name: route.name,
  creatives: slides.map((slide) => ({ creative: slide.image?.alt })),
}))

const trackSlideClick =
  (slide: CarouselProps['slides'][0], index: number) => () => {
    trackCarouselClick({
      creative: slide.image?.alt,
      name: String(route.name),
      position: `${String(route.name)}_${index}`,
    })
  }

const carouselSlides = computed(() => {
  return slides.map((slide, index) => {
    const hasLink = !isEmpty(slide.link)

    return {
      ...slide,
      tag: hasLink ? RevButtonBase : 'div',
      key: slide.image?.src,
      target: slide.link?.target,
      isLoading: index === 0 ? Loading.Eager : Loading.Lazy,
      handleSlideClick: hasLink ? trackSlideClick(slide, index) : undefined,
    }
  })
})

const trackingData = computed(() => {
  if (Array.isArray(tracking) && !isEmpty(tracking)) {
    return tracking
  }

  return {
    name: String(route.name),
    creatives: slides.map((slide) => ({ creative: slide.image?.alt })),
  }
})
</script>
